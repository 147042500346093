@layer base {
  @font-face {
    font-family: "Aeonik";
    src: url(./fonts/Aeonik-BoldItalic.woff2) format("woff2"),
      url(./fonts/Aeonik-BoldItalic.woff) format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Aeonik";
    src: url(./fonts/Aeonik-Bold.woff2) format("woff2"),
      url(./fonts/Aeonik-Bold.woff) format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Aeonik";
    src: url(./fonts/Aeonik-RegularItalic.woff2) format("woff2"),
      url(./fonts/Aeonik-RegularItalic.woff) format("woff");
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: "Aeonik";
    src: url(./fonts/Aeonik-Regular.woff2) format("woff2"),
      url(./fonts/Aeonik-Regular.woff) format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Aeonik";
    src: url(./fonts/Aeonik-LightItalic.woff2) format("woff2"),
      url(./fonts/Aeonik-LightItalic.woff) format("woff");
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: "Aeonik";
    src: url(./fonts/Aeonik-Light.woff2) format("woff2"),
      url(./fonts/Aeonik-Light.woff) format("woff");
    font-weight: 100;
    font-style: normal;
  }
}
